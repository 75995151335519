<template>
  <div>
    <div class="container mt-2">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div
              class="col-12 d-flex card-header"
              style="background-color: #006203; color: #ffffff"
            >
              <h4 class="card-title col-6 text-justify">
                TERMS& CONDTION FOR USING APPD PREPAER AND DRAFT BY AL SALEH &
                ISKINDRANI LAWFIRM
              </h4>
              <h4 class="card-title col-6 ps-2">
                نموذج الأحكام والشروط الخاصة باستخدام تطبيق<br />
                صياغة واعداد / شركة الصالح واسكندراني للمحاماة
              </h4>
            </div>
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Terms & Condition to the APP----------------</p></u>
                    <div readonly>
                      About application / ---------------The company was
                      established -------------- and it is the owner of the
                      application (--------) The company has been registered in
                      -----------, and its address is - ---------- -.- And carry
                      the commercial register number --------------------
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u
                      ><p>
                        الأحكام والشروط الخاصة بتطبيق /-------------------------
                      </p></u
                    >
                    <div readonly>
                      عن تطبيق /--------------- تم تأسيس الشركة --------------
                      وهي المالكة لتطبيق (--------) وقد تم تسجيل الشركة في
                      -----------، وعنوانها ------------ -.- وتحمل السجل التجاري
                      ذي الرقم --------------------
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Preamble</p></u>
                    <div readonly>
                      The following terminology applies to these Terms and
                      Conditions, Privacy Statement and Disclaimer Notice and
                      any or all Agreements: "Client", “You” and “Your” refers
                      to you, the person accessing this website and accepting
                      the Company’s terms and conditions. "The Company",
                      “Ourselves”, “We” and "Us", refers to our Company.
                      “Party”, “Parties”, or “Us”, refers to both the Client and
                      ourselves, or either the Client or ourselves. All terms
                      refer to the offer, acceptance and consideration of
                      payment necessary to undertake the process of our
                      assistance to the Client in the most appropriate manner,
                      whether by formal meetings of a fixed duration, or any
                      other means, for the express purpose of meeting the
                      Client’s needs in respect of provision of the Company’s
                      stated services/products, in accordance with and subject
                      to, prevailing SAUDI regulations . Any use of the above
                      terminology or other words in the singular, plural,
                      capitalization and/or he/she or they, are taken as
                      interchangeable and therefore as referring to same.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>المقدمة</p></u>
                    <div readonly>
                      عند استخدامك (اسم التطبيق) هذا ، يُعتبر أنك قد قرأت ووافقت
                      على الشروط والأحكام التالية: تنطبق المصطلحات التالية على
                      هذه الشروط والأحكام وبيان الخصوصية وإشعار إخلاء المسؤولية
                      وأي أو كل الاتفاقيات: يشير مصطلح "العميل" و "أنت" و "الخاص
                      بك" إليك ، أي الشخص الذي يصل إلى هذا الموقع ويقبل شروط
                      وأحكام الشركة. تشير "الشركة" و "أنفسنا" و "نحن" و "نحن"
                      إلى شركتنا. يشير "الطرف" أو "الأطراف" أو "نحن" إلى كل من
                      العميل وأنفسنا ، أو إما العميل أو أنفسنا. تشير جميع الشروط
                      إلى العرض والقبول والنظر في الدفع اللازم للاضطلاع بعملية
                      مساعدتنا للعميل بالطريقة الأنسب ، سواء عن طريق الاجتماعات
                      الرسمية لمدة محددة ، أو أي وسيلة أخرى ، لغرض صريح وهو
                      تلبية احتياجات العميل فيما يتعلق بتوفير الخدمات / المنتجات
                      المعلنة للشركة ، وفقًا للقانون الإنجليزي السائد ويخضع له.
                      أي استخدام للمصطلحات المذكورة أعلاه أو غيرها من الكلمات في
                      صيغة المفرد والجمع و / أو هو / هي أو هم ، يتم اعتباره
                      قابلاً للتبادل وبالتالي يشير إلى نفسه.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Privacy Statement</p></u>
                    <div readonly>
                      We are committed to protecting your privacy. Authorized
                      employees within the company on a need-to-know basis only
                      use any information collected from individual customers.
                      We constantly review our systems and data to ensure the
                      best possible service to our customers. The Government has
                      created specific offences for unauthorized actions against
                      computer systems and data and through using mobile and
                      internet . We will investigate any such actions with a
                      view to prosecuting and/or taking civil proceedings to
                      recover damages against those responsible.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>بيان الخصوصية</p></u>
                    <div readonly>
                      ونحن ملتزمون بحماية خصوصيتك. يستخدم الموظفون المعتمدون
                      داخل الشركة على أساس الحاجة إلى المعرفة فقط أي معلومات تم
                      جمعها من العملاء الأفراد. نراجع باستمرار أنظمتنا وبياناتنا
                      لضمان أفضل خدمة ممكنة لعملائنا. أنشأت الحكومة عقوبات على
                      الجرائم المحددة للأعمال غير المصرح بها ضد أنظمة الكمبيوتر
                      والبيانات ومن خلال استخدام الهاتف والانترنت. سنحقق في أي
                      من هذه الإجراءات بهدف مقاضاة و / أو اتخاذ إجراءات مدنية
                      لاسترداد الأضرار ضد المسؤولين.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Confidentiality</p></u>
                    <div readonly>
                      Any information concerning the Client and their respective
                      Client Records may be passed to third parties. However,
                      Client records are regarded as confidential and therefore
                      will not be divulged to any third party, other than
                      [Driver or service provider / (s) and ] if legally
                      required to do so to the appropriate authorities. Clients
                      have the right to request sight of, and copies of any and
                      all Client Records we keep, on the proviso that we are
                      given reasonable notice of such a request. Clients are
                      requested to retain copies of any literature issued in
                      relation to the provision of our services. Where
                      appropriate, we shall issue Client’s with appropriate
                      written information, handouts, or copies of records as
                      part of an agreed contract, for the benefit of both
                      parties. We will not sell, share, or rent your personal
                      information to any third party or use your e-mail address
                      for unsolicited mail. Any emails sent by this Company will
                      only be in connection with the provision of agreed
                      services and products.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>سرية</p></u>
                    <div readonly>
                      قد يتم تمرير أي معلومات تتعلق بالعميل وسجلات العميل الخاصة
                      به إلى أطراف ثالثة. ومع ذلك ، تعتبر سجلات العميل سرية ،
                      وبالتالي لن يتم الكشف عنها لأي طرف ثالث ، بخلاف [الشركة
                      المصنعة / المورد (الموردين) لدينا و] إذا كان مطلوبًا
                      قانونًا القيام بذلك إلى السلطات المختصة. يحق للعملاء طلب
                      الاطلاع على ونسخ أي وكل سجلات العميل التي نحتفظ بها ، بشرط
                      أن يتم إخطارنا بشكل معقول بهذا الطلب. يطلب من العملاء
                      الاحتفاظ بنسخ من أي مؤلفات صادرة فيما يتعلق بتقديم
                      خدماتنا. عند الاقتضاء ، سنصدر للعميل المعلومات المكتوبة
                      المناسبة أو النشرات أو نسخ السجلات كجزء من عقد متفق عليه ،
                      لصالح كلا الطرفين. لن نبيع أو نشارك أو نؤجر معلوماتك
                      الشخصية لأي طرف ثالث أو نستخدم عنوان بريدك الإلكتروني
                      للبريد غير المرغوب فيه. أي رسائل بريد إلكتروني ترسلها هذه
                      الشركة ستكون مرتبطة فقط بتقديم الخدمات والمنتجات المتفق
                      عليها.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Disclaimer</p></u>
                    <div readonly>
                      Exclusions and Limitations The information on this web
                      site is provided on an "as is" basis. To the fullest
                      extent permitted by law, this Company: Excludes all
                      representations and warranties relating to this website
                      and its contents or which is or may be provided by any
                      affiliates or any other third party, including in relation
                      to any inaccuracies or omissions in this website and/or
                      the Company’s literature; and Excludes all liability for
                      damages arising out of or in connection with your use of
                      this website. This includes, without limitation, direct
                      loss, loss of business or profits (whether or not the loss
                      of such profits was foreseeable, arose in the normal
                      course of things or you have advised this Company of the
                      possibility of such potential loss), damage caused to your
                      computer, computer software, systems and programs and the
                      data thereon or any other direct or indirect,
                      consequential and incidental damages.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>إخلاء مسؤولية</p></u>
                    <div readonly>
                      الاستثناءات والقيود يتم توفير المعلومات الواردة في هذا
                      الموقع على أساس "كما هي". إلى أقصى حد يسمح به القانون ،
                      هذه الشركة: 1- يستثني جميع الإقرارات والضمانات المتعلقة
                      بهذا الموقع - ومحتوياته أو التي يتم توفيرها أو قد يتم
                      توفيرها من قبل أي شركات تابعة أو أي طرف ثالث ، بما في ذلك
                      ما يتعلق بأي أخطاء أو سهو في هذا الموقع و / أو أدبيات
                      الشركة ؛ 2- يستثني كل المسؤولية عن الأضرار الناشئة عن أو
                      فيما يتعلق باستخدامك لهذا الموقع. وهذا يشمل ، على سبيل
                      المثال لا الحصر ، الخسارة المباشرة أو خسارة الأعمال أو
                      الأرباح (سواء كانت خسارة هذه الأرباح متوقعة أم لا ، أو
                      نشأت في السياق الطبيعي للأشياء أو أنك أبلغت هذه الشركة
                      بإمكانية حدوث مثل هذه الخسارة المحتملة) ، الضرر الناجم
                      لجهاز الكمبيوتر الخاص بك ، وبرامج الكمبيوتر والأنظمة
                      والبرامج والبيانات الموجودة عليها أو أي أضرار تبعية وعرضية
                      أخرى مباشرة أو غير مباشرة.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Payment</p></u>
                    <div readonly>
                      Cash or with Bankers Card, all major Credit/Debit Cards,
                      are all acceptable methods of payment. Our Terms are
                      payment in full within thirty days. All goods remain the
                      property of the Company until paid for in full. . We
                      reserve the right to seek recovery of any monies remaining
                      unpaid sixty days from the date of invoice via collection
                      Agencies and/or through the court or police or any
                      official government order. In such circumstances, you
                      shall be liable for any and all additional administrative
                      and/or court costs. [Amend as appropriate]
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>إخلاء مسؤولية</p></u>
                    <div readonly>
                      الرئيسية ، كلها طرق مقبولة للدفع. شروطنا هي السداد بالكامل
                      في غضون ثلاثين يومًا. تظل جميع البضائع ملكًا للشركة حتى
                      يتم سداد ثمنها بالكامل. . نحتفظ بالحق في طلب استرداد أي
                      أموال متبقية غير مدفوعة بعد ستين يومًا من تاريخ الفاتورة
                      عبر وكالات التحصيل و / أو من خلال المحكمة أو الشرطة أو أي
                      أمر رسمي. في مثل هذه الظروف ، ستكون مسؤولاً عن أي وجميع
                      التكاليف الإدارية و / أو المحكمة الإضافية. [يعدل حسب
                      الاقتضاء]
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Cancellation Policy</p></u>
                    <div readonly>
                      Minimum 24 hours notice of cancellation required.
                      Notification for instance, in person, via email, mobile
                      phone ‘text message’ and/, or any other means will be
                      accepted subject to confirmation in writing. We reserve
                      the right to levy a ------- SAR charge to cover any
                      subsequent administrative expenses
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>سياسة الإلغاء</p></u>
                    <div readonly>
                      يجب إخطار الإلغاء قبل 24 ساعة على الأقل. سيتم قبول الإخطار
                      ، على سبيل المثال ، شخصيًا ، عبر البريد الإلكتروني ، و /
                      أو "رسالة نصية" للهاتف المحمول و / أو أي وسيلة أخرى تخضع
                      للتأكيد كتابةً ونحتفظ بالحق في فرض رسوم ------- ريال سعودي
                      لتغطية أي نفقات إدارية لاحقة.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Termination of Agreements and Refunds Policy</p></u>
                    <div readonly>
                      Both the Client and ourselves have the right to terminate
                      any Services Agreement for any reason, including the
                      ending of services that are already underway. No refunds
                      shall be offered, where a Service is deemed to have begun
                      and is, for all intents and purposes, underway. Any monies
                      that have been paid to us which constitute payment in
                      respect of the provision of unused Services, shall be
                      refunded.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>سياسة الإلغاء</p></u>
                    <div readonly>
                      لكل من العميل وأنفسنا الحق في إنهاء أي اتفاقية خدمات لأي
                      سبب ، بما في ذلك إنهاء الخدمات الجارية بالفعل. لن يتم
                      تقديم أي مبالغ مستردة ، حيث تعتبر الخدمة قد بدأت وجارية ،
                      لجميع النوايا والأغراض. سيتم رد أي أموال تم دفعها إلينا
                      والتي تشكل دفعة فيما يتعلق بتوفير الخدمات غير المستخدمة.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Availability</p></u>
                    <div readonly>
                      Unless otherwise stated, the services offered on this
                      website are only available within the Kingdom of Saudi
                      Arabia, or in connection with publications from the
                      Kingdom of Saudi Arabia. All ads are intended only for the
                      KSA market. You are solely responsible for evaluating the
                      suitability of any downloads, programs and texts available
                      through this website for a particular purpose. It is
                      prohibited to redistribute or republish any part of this
                      site or its content, including by framing or any other
                      similar means or any other means, without the express
                      written consent of the company. The Company does not
                      guarantee that the service from this website will not be
                      interrupted, timely or error-free, despite being provided
                      in the best capacity. By using this service, you thereby
                      indemnify this company and its employees, agents and
                      subsidiaries for any loss or damage, in any way, and for
                      whatever reason.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>التوفر</p></u>
                    <div readonly>
                      ما لم يُنص على خلاف ذلك ، فإن الخدمات المقدمة على هذا
                      الموقع متاحة فقط داخل المملكة العربية السعودية ، أو فيما
                      يتعلق بالمنشورات من المملكة العربية السعودية. جميع
                      الإعلانات مخصصة فقط لسوق المملكة العربية السعودية. أنت
                      وحدك المسؤول عن تقييم مدى ملاءمة أي تنزيلات وبرامج ونصوص
                      متاحة من خلال هذا الموقع الإلكتروني لغرض معين. يحظر إعادة
                      توزيع أو إعادة نشر أي جزء من هذا الموقع أو محتواه ، بما في
                      ذلك عن طريق التأطير أو أي وسيلة أخرى مماثلة أو أي وسيلة
                      أخرى ، دون موافقة خطية صريحة من الشركة. لا تضمن الشركة أن
                      الخدمة من هذا الموقع لن تنقطع أو تكون في الوقت المناسب أو
                      خالية من الأخطاء ، على الرغم من تقديمها بأفضل السعة.
                      باستخدام هذه الخدمة ، فإنك بذلك تعوض هذه الشركة وموظفيها
                      ووكلائها والشركات التابعة لها عن أي خسارة أو ضرر ، بأي شكل
                      من الأشكال ، ولأي سبب كان.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Log Files</p></u>
                    <div readonly>
                      Unless otherwise stated, the services offered on this
                      website are only available within the Kingdom of Saudi
                      Arabia, or in connection with publications from the
                      Kingdom of Saudi Arabia. All ads are intended only for the
                      KSA market. You are solely responsible for evaluating the
                      suitability of any downloads, programs and texts available
                      through this website for a particular purpose. It is
                      prohibited to redistribute or republish any part of this
                      site or its content, including by framing or any other
                      similar means or any other means, without the express
                      written consent of the company. The Company does not
                      guarantee that the service from this website will not be
                      interrupted, timely or error-free, despite being provided
                      in the best capacity. By using this service, you thereby
                      indemnify this company and its employees, agents and
                      subsidiaries for any loss or damage, in any way, and for
                      whatever reason.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>ملفات الدخول</p></u>
                    <div readonly>
                      نستخدم عناوين IP لتحليل الاتجاهات وإدارة الموقع وتتبع حركة
                      المستخدم وجمع معلومات ديموغرافية واسعة النطاق للاستخدام
                      الكلي. لا ترتبط عناوين IP بمعلومات التعريف الشخصية.
                      بالإضافة إلى ذلك ، بالنسبة لإدارة الأنظمة ، واكتشاف أنماط
                      الاستخدام وأغراض استكشاف الأخطاء وإصلاحها ، تقوم خوادم
                      الويب لدينا تلقائيًا بتسجيل معلومات الوصول القياسية بما في
                      ذلك نوع المتصفح ، وأوقات الوصول / البريد المفتوح ، وعنوان
                      URL المطلوب ، وعنوان URL للإحالة. لا تتم مشاركة هذه
                      المعلومات مع جهات خارجية ويتم استخدامها فقط داخل هذه
                      الشركة على أساس الحاجة إلى المعرفة. لن يتم استخدام أي
                      معلومات تعريف فردية تتعلق بهذه البيانات بأي طريقة تختلف عن
                      تلك المذكورة أعلاه دون إذن صريح منك.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Cookies</p></u>
                    <div readonly>
                      Like most interactive web sites this Company’s website [or
                      ISP] uses cookies to enable us to retrieve user details
                      for each visit. Cookies are used in some areas of our site
                      to enable the functionality of this area and ease of use
                      for those people visiting. Some of our affiliate partners
                      may also use cookies. [If you do not use cookies, delete
                      this clause]
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>ملفات تعريف الارتباط</p></u>
                    <div readonly>
                      مثل معظم مواقع الويب التفاعلية ، يستخدم موقع الويب الخاص
                      بهذه الشركة [أو مزود خدمة الإنترنت] ملفات تعريف الارتباط
                      لتمكيننا من استرداد تفاصيل المستخدم لكل زيارة. تُستخدم
                      ملفات تعريف الارتباط في بعض مناطق موقعنا لتمكين وظائف هذه
                      المنطقة وسهولة الاستخدام لأولئك الذين يزورونها. قد يستخدم
                      بعض شركائنا التابعين أيضًا ملفات تعريف الارتباط. [إذا كنت
                      لا تستخدم ملفات تعريف الارتباط ، فاحذف هذه الفقرة]
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Links to this website</p></u>
                    <div readonly>
                      You may not create a link to any page of this website
                      without our prior written consent. If you do create a link
                      to a page of this website you do so at your own risk and
                      the exclusions and limitations set out above will apply to
                      your use of this website by linking to it.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>روابط لهذا الموقع</p></u>
                    <div readonly>
                      لا يجوز لك إنشاء رابط إلى أي صفحة من صفحات هذا الموقع دون
                      موافقتنا الخطية المسبقة. إذا قمت بإنشاء ارتباط إلى صفحة من
                      هذا الموقع ، فإنك تقوم بذلك على مسؤوليتك الخاصة ، وستنطبق
                      الاستثناءات والقيود الموضحة أعلاه على استخدامك لهذا الموقع
                      عنطريق الارتباط به.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Links from this website</p></u>
                    <div readonly>
                      We do not monitor or review the content of other party’s
                      websites which are linked to from this website. Opinions
                      expressed or material appearing on such websites are not
                      necessarily shared or endorsed by us and should not be
                      regarded as the publisher of such opinions or material.
                      Please be aware that we are not responsible for the
                      privacy practices, or content, of these sites. We
                      encourage our users to be aware when they leave our site &
                      to read the privacy statements of these sites. You should
                      evaluate the security and trustworthiness of any other
                      site connected to this site or accessed through this site
                      yourself, before disclosing any personal information to
                      them. This Company will not accept any responsibility for
                      any loss or damage in whatever manner, howsoever caused,
                      resulting from your disclosure to third parties of
                      personal information.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>روابط من هذا الموقع</p></u>
                    <div readonly>
                      نحن لا نراقب أو نراجع محتوى مواقع الأطراف الأخرى المرتبطة
                      من هذا الموقع. الآراء المعبر عنها أو المواد التي تظهر على
                      هذه المواقع ليست بالضرورة مشتركة أو معتمدة من قبلنا ولا
                      ينبغي اعتبارها ناشرًا لهذه الآراء أو المواد. يرجى العلم
                      أننا لسنا مسؤولين عن ممارسات الخصوصية أو المحتوى لهذه
                      المواقع. نشجع مستخدمينا على أن يكونوا على علم عندما
                      يغادرون موقعنا وأن يقرأوا بيانات الخصوصية لهذه المواقع.
                      يجب عليك تقييم أمان ومصداقية أي موقع آخر متصل بهذا الموقع
                      أو يتم الوصول إليه من خلال هذا الموقع بنفسك ، قبل الكشف عن
                      أي معلومات شخصية لهم. لن تتحمل هذه الشركة أي مسؤولية عن أي
                      خسارة أو ضرر بأي طريقة ، ومهما كان السبب ، الناتج عن
                      إفصاحك عن معلومات شخصية لأطراف ثالثة.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>Copyright Notice</p></u>
                    <div readonly>
                      1- Copyright and other relevant intellectual property
                      rights exists on all text relating to the Company’s
                      services and the full content of this website. 2- This
                      Company’s logo is a registered trademark of this Company
                      in the United Kingdom and other countries. The brand names
                      and specific services of this Company featured on this web
                      site are trade marked [delete this paragraphed clause if
                      no registered trademark exists].
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <u><p>حقوق النشر محفوظة</p></u>
                    <div readonly>
                      نحن لا نراقب أو نراجع محتوى مواقع الأطراف الأخرى المرتبطة
                      من هذا الموقع. الآراء المعبر عنها أو المواد التي تظهر على
                      هذه المواقع ليست بالضرورة مشتركة أو معتمدة من قبلنا ولا
                      ينبغي اعتبارها ناشرًا لهذه الآراء أو المواد. يرجى العلم
                      أننا لسنا مسؤولين عن ممارسات الخصوصية أو المحتوى لهذه
                      المواقع. نشجع مستخدمينا على أن يكونوا على علم عندما
                      يغادرون موقعنا وأن يقرأوا بيانات الخصوصية لهذه المواقع.
                      يجب عليك تقييم أمان ومصداقية أي موقع آخر متصل بهذا الموقع
                      أو يتم الوصول إليه من خلال هذا الموقع بنفسك ، قبل الكشف عن
                      أي معلومات شخصية لهم. لن تتحمل هذه الشركة أي مسؤولية عن أي
                      خسارة أو ضرر بأي طريقة ، ومهما كان السبب ، الناتج عن
                      إفصاحك عن معلومات شخصية لأطراف ثالثة.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsAndCondition",
  data() {
    return {};
  },
};
</script>

<style></style>
